@import './theme';

.ant-btn {
  border-color: $primary-color;
  height: unset;
  border-radius: 8px;
  padding: 7px 35px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.005em;

  &:hover {
    color: $bg-color;
    background: $primary-color-hover;
    border-color: $primary-color-hover;
  }
  &:focus {
    color: black;
    border-color: $primary-color-hover;
  }
  &.ant-btn-primary {
    background: $primary-color;
    &[disabled] {
      background: $bg-color;
    }
    &:focus {
      color: $bg-color;
    }
  }

  &.ant-btn-link {
    padding: 0;
    border: none;
    color: $primary-color;
    &:hover, &:focus {
      color: $primary-color;
      background: white;
      border-color: white;
    }
  }

  &.ant-btn-circle.ant-btn-icon-only {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
      background: $primary-color-hover;
      border-color: $primary-color-hover;
    }
  }

  &.ant-btn-dangerous {
    color: red;
    border-color: red;
    &:hover {
      background-color: red;
      color: white;
    }
  }
}

a {
  color: $primary-color;
  &:hover, &:focus {
    color: $primary-color-hover;
  }
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color:  $primary-color;
}
.ant-checkbox-checked::after {
  border: 1px solid $primary-color;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-radio-group.ant-radio-group-outline {
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.005em;
  .ant-radio-button-wrapper {
    border: none;
    font-size: 16px;
    background: transparent;
    &.ant-radio-button-wrapper-checked {
      border: none;
      box-shadow: none;
      color: $primary-color;
      text-shadow: 1px 1px $primary-color;
    }
    &:first-child {
      padding-left: 0;
    }
    &:hover {
      color: $primary-color;
    }
    &::before {
      content: none;
    }
  }

  &.ant-radio-group-outline {
    .ant-radio-inner {
      border-color: $primary-color;
      background-color: $bg-color;
      width: 24px;
      height: 24px;
      &::after {
        background-color: $primary-color;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
      }
    }
    .ant-radio-checked {
      &::after {
        border-color: $primary-color;
      }
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .ant-radio-wrapper {
    &>span:nth-child(2) {
      align-self: center;
    }
  }
}

button.ant-switch {
  &.ant-switch-checked {
    background-color: $primary-color;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px;
    .ant-modal-title {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

.ant-pagination.ant-table-pagination.ant-table-pagination-center {
  .ant-pagination-item-active, .ant-pagination-item:hover {
    border-color: $primary-color;
    a {
      color: $primary-color;
    }
  }
  .ant-pagination-next:hover, .ant-pagination-prev:hover{
    .ant-pagination-item-link {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.ant-spin-dot-item {
  background-color: $primary-color;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  font-size: 14px;
  font-weight: 400;
  width: 100%;

  padding: 3px 0 3px 16px;
  border-radius: 8px;
  border: 2px solid $primary-color-light;
  &:focus {
    box-shadow: 0 0 0 2px $primary-color-shadow;
  }
  .ant-select-selector {
    border:none;
    padding-left: 0;
    &:focus {
      box-shadow: none;
    }
  }
  &.ant-select-open {
    box-shadow: 0 0 0 2px $primary-color-shadow;
    .ant-select-selector {
      box-shadow: none;
    }
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
.ant-select.ant-select-single.ant-select-show-arrow.ant-select-disabled {
  background: $bg-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}

.ant-picker-focused, .ant-picker:hover, .ant-picker-focused {
  border-color: $primary-color;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: $primary-color;
  }
}

.ant-picker {
  font-size: 14px;
  font-weight: 400;
  width: 100%;

  padding: 8px 8px 8px 16px;
  border-radius: 8px;
  border: 2px solid $bg-color;
  &:focus {
    box-shadow: 0 0 0 2px $primary-color-shadow;
  }
  .ant-select-selector {
    border:none;
    padding-left: 0;
    &:focus {
      box-shadow: none;
    }
  }
  &.ant-select-open {
    box-shadow: 0 0 0 2px $primary-color-shadow;
    .ant-select-selector {
      box-shadow: none;
    }
  }
}

.ant-tabs-content-holder {
  padding: 0 5px;
}

