@import "../../../../styles/theme";

.form-section {
  padding: 24px;
  background-color: white;
  border-radius: 8px;

  &.no-padding{
    padding: 0;
    .form-field .label{
      padding: 0;
    }
  }

  .form-section-header {
    h3 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.005em;
      margin-bottom: 0;
    }
  }

  .form-field {
    padding: 10px 0;
    position: relative;

    .label {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.008em;
      padding-left: 19px;
      padding-bottom: 2px;
    }
    .ant-input, .ant-picker {
      font-size: 14px;
      font-weight: 400;

      padding: 8px 16px;
      border-radius: 8px;
      border: 2px solid $primary-color-light;
      &:focus {
        box-shadow: 0 0 0 2px $primary-color-shadow;
      }
    }

    .number-input-with-controller {
      width: 225px;
      border: 2px solid $primary-color-light;
      border-radius: 8px;
      padding: 0;
      .ant-btn {
        border: none;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 0;
      }
      &.ant-input-affix-wrapper:hover, &.ant-input-affix-wrapper:focus {
        box-shadow: 0 0 0 2px $primary-color-shadow;
        border-color: $primary-color;
      }

      .ant-input {
        border-radius: 0;
        border-top: none;
        border-bottom: none;
        text-align: center;
      }

    }

    .ant-select {
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      padding: 3px 0 3px 16px;
      border-radius: 8px;
      border: 2px solid $primary-color-light;
      &:focus {
        box-shadow: 0 0 0 2px $primary-color-shadow;
      }
      .ant-select-selector {
        border:none;
        padding-left: 0;
        &:focus {
          box-shadow: none;
        }
      }
      &.ant-select-open {
        box-shadow: 0 0 0 2px $primary-color-shadow;
        .ant-select-selector {
          box-shadow: none;

        }
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }

    .rg-company-role {
      padding: 7px 0 0 14px;
    }

    &.error {
      position: relative;
      .label {
        color: red;
      }
      .ant-input, .ant-select, .ant-picker {
        border-color: #ff8383;
        &:focus {
          box-shadow: 0 0 0 1px #ff838388;
        }
        &.ant-select-open {
          box-shadow: 0 0 0 1px #ff838388;
        }
      }
    }

    &.priority-radio-group {
      .ant-radio-group.ant-radio-group-outline {
        &.ant-radio-group-outline {
          label:nth-child(1) {
            .ant-radio-inner {
              border-color: yellow;
              background-color: #fffedf;
              &::after {
                background-color: yellow;
              }
            }
            .ant-radio-checked {
              &::after {
                border-color: yellow;
              }
            }
          }
          label:nth-child(2) {
            .ant-radio-inner {
              border-color: orange;
              background-color: #ffedd3;
              &::after {
                background-color: orange;
              }
            }
            .ant-radio-checked {
              &::after {
                border-color: orange;
              }
            }
          }
          label:nth-child(3) {
            .ant-radio-inner {
              border-color: red;
              background-color: #f4d7d7;
              &::after {
                background-color: red;
              }
            }
            .ant-radio-checked {
              &::after {
                border-color: red;
              }
            }
          }

        }
      }
    }
  }

  .error-message {
    position: absolute;
    left: 20px;
    bottom: -6px;
    font-size: 11px;
    color: red
  }

  .w-200px {
    width: 200px;
  }
  .h-200px {
    height: 200px;
  }
}

.ant-input-group-addon {
  background-color: transparent;
  border: none;
}
