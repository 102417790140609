.list-item {
  margin-bottom: 10px;
  &.ant-collapse-item {
    box-shadow: 2px 2px 6px -2px black;
    -webkit-box-shadow: 2px 2px 6px -2px black;
    -moz-box-shadow: 2px 2px 6px -2px black;
    border-radius: 8px;

    .ant-collapse-header {
      border-radius: 8px;
      display: flex;
      align-items: center;

      .ant-collapse-header-text {
        flex: auto;

        .icon {
          font-size: 30px;
          color: white;
          background: black;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          text-align: center;
          margin-right: 15px;
        }

        .titles {
          .title {
            font-size: 15px;
            font-weight: bold;
          }

          .sub-title {
            font-size: 12px;
            line-height: 10px;
          }
        }
      }
    }

    .ant-collapse-content-box {
      background: #fff7df;
      display: flex;
      align-items: center;

      .ant-descriptions-row > td {
        padding-bottom: 0;
      }
    }

    &:last-child {
      border-radius: 8px;
    }
  }

  .list-item {
    padding: 10px;
    border: 1px solid gray;
  }
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  border-radius: 8px;
}