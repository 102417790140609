.sub-page-layout {
  .sp-header {
    padding-top: 8px;
    .left {
      h1 {
        font-size: 31px;
        font-weight: 700;
        letter-spacing: 0.005em;
        margin-bottom: 0;
        line-height: 1.4;
      }
    }
  }
  .sb-content {
    padding-top: 27px;
  }
}
