@import "../../../../styles/theme";

.addon-item {
  border: 1px solid $primary-color;
  border-radius: 8px;
  margin: 5px;
  padding: 5px;
  background-color: white;

  .addon-name {
    font-weight: bold;
    font-size: 15px;
  }

  .ant-btn.ant-btn-circle.ant-btn-icon-only:hover {
    background: red;
  }
  .remove-button {
    width: 30px !important;
    height: 30px !important;
  }
}
.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  background: white;
}

.ant-modal-body {
  padding: 20px !important;
}

.addon-group {
  background-color: #fff9f1;
  padding: 5px;
  border: 1px solid $primary-color;
  border-radius: 8px;
  margin: 10px 0;

  &.active {
    background-color: #ffe8c6;
  }

  .ant-checkbox-wrapper {
    span:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
    }
  }
}