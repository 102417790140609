@import "src/styles/theme";

.login-container {
  background-color: #F3F6F9;
  //
  //.left-panel, .right-panel {
  //  width: 600px;
  //}

  .logo>img {
    width: 210px;
  }
  .left-panel {
    .welcome {
      font-size: 40px;
      line-height: 60px;
      margin-top: 28px;
      margin-bottom: 0;
    }
    .welcome-sub {
      font-size: 24px;
      line-height: 36px;
      margin-top: 5px;
      letter-spacing: .01em;
    }
  }

  .right-panel {
    .form-container {
      background-color: white;
      border-radius: 30px;
      padding: 88px;

      .title {
        font-size: 48px;
        letter-spacing: 0;
        color: #36455A;
      }
      .sub-title {
        font-size: 16px;
        letter-spacing: 0;
        color: #6D7785;
        margin-top: 7px;
      }

      form {
        padding-top: 70px;
        .login-field {
          margin-bottom: 54px;
          label {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0;
          }
          span.ant-input-affix-wrapper {
            margin-top: 17px;
            border: none;
            border-bottom: 2px solid $primary-color-shadow;
            padding-left: 0;
            padding-right: 0;

            &:focus, &.ant-input-affix-wrapper-focused {
              border-bottom: 2px solid $primary-color;
              box-shadow: none;
            }

            .ant-input-suffix {
              color: $primary-color;
              font-size: 20px;
            }

            input {
              font-weight: 500;
              font-size: 18px;
              color: #36455A;
            }

            &.has-error {
              border-bottom: 2px solid #ff0000;
              .ant-input-suffix {
                color: red;
              }
            }
          }

        }

        .remember-me-forgot-password {
          margin-bottom: 30px;

          .ant-checkbox-wrapper {
            color: #6A6F7D;
          }
        }
      }
    }
  }
}
