@import "~normalize.css";

@import '~antd/dist/antd';
@import "./override-antd";

@import "./common";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '~react-toastify/dist/ReactToastify.css';

@import "theme";

body, .App {
  height: 100vh;

  .poppins {
    font-family: 'Poppins', sans-serif;
  }
  .mulish {
    font-family: 'Mulish', sans-serif;
  }
  letter-spacing: .02em;

  .loading-screen {
    background-color: #6A6F7D;
    .techila-name {
      background: linear-gradient(to right, $primary-color 0%, #000000 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: $bg-color;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: $bg-color;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #7a7a7a;
}
