// flex
.d-flex { display: flex; }
.flex { flex: 1 };
.flex-no-shrink { flex-shrink: 0 };
.f-row { flex-direction: row; }
.f-col { flex-direction: column; }
.f-wrap { flex-wrap: wrap; }

// flex align and justify
.ai-center { align-items: center; }
.jc-center { justify-content: center; }
.jc-space-between { justify-content: space-between; }
.jc-space-around { justify-content: space-around; }
.jc-end { justify-content: flex-end; }

// text transform
.upper-case { text-transform: uppercase; }

// font-weight
@for $i from 1 to 8 {
  .fw-#{$i * 100} { font-weight : $i * 100; };
}

.rounded-border { border-radius: 8px }

// sizes
.h-100 { height: 100%; }
.w-100 { width: 100% }

// background colors
.bg-white { background-color: white; }

.space-left { margin-left: 15px; }
.space-right { margin-right: 15px; }
.space-top { margin-top: 15px; }
.space-bottom { margin-bottom: 15px; }
.space-all { margin: 15px; }
.space-left-p { padding-left: 15px; }
.space-right-p { padding-right: 15px; }
.space-top-p { padding-top: 15px; }
.space-bottom-p { padding-bottom: 15px; }
.space-all-p { padding: 15px; }
