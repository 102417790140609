@import "src/styles/theme.scss";

.layout {
  height: 100%;
  .header {
    height: 80px;

    .logo {
      width: 100px;
      padding: 5px;
      margin-left: 78px;
    }

    .profile-dropdown {
      padding: 10px 36px;
      .text {
        padding-left: 10px;
        .name {
          font-size: 16px;
          color: #11142D;
        }
        .email {
          font-size: 12px;
          color: #92929D;
        }
      }
      .anticon {
        font-size: 20px;
      }
      .ant-avatar {
        color: $primary-color;
        background-color: #D7F4DD;
      }
    }

    background: #000000;
  }
  .body {
    max-height: calc(100% - 80px);
    .side-bar {
      flex: 0 0 256px;
      padding: 10px;
      border-right: 1px solid #E1E1E1;
      background: #000000;

      .profile-info {
        padding: 30px 10px 10px 20px;
        .ant-avatar {
          border-radius: 8px;
          background-color: #E2E2EA;
        }
        .profile-details {
          padding: 10px 0 0 16px;
          .name {
            font-size: 16px;
            color: #11142D;
            margin-bottom: 0;
          }
          .email {
            font-size: 9px;
            color: $primary-color;
            padding-top: 2px;
          }
        }
      }

      hr.divider {
        border-color: #E1E1FB;
        margin-top: 12px;
        width: 85%;
      }

      .side-bar-menu {
        padding-top: 15px;
        flex: 1;
        .menu-item {
          display: flex;
          margin: 2px;
          padding: 17px;
          border-radius: 8px;
          .icon {
            font-size: 25px;
            width: 20px;
            color: white;
          }
          .title {
            font-weight: 700;
            font-size: 16px;
            color: #ffffff;
            padding-left: 20px;
          }
          &.active {
            background-color: $primary-color;
            .title {
              color: #ffffff;
            }
          }
          &.logout {
            background: #F1840044;
          }
        }
      }
    }

    .content {
      max-height: 100%;
      overflow: auto;
      padding: 20px 31px;
      flex: 1;
      background-color:  #f7f7fc;
    }
  }
}


.search-filter {
  background: white;
  width: 450px;
  padding: 15px;
  .ant-input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.008em;
    border-color: $primary-color;
    padding: 8px;
    box-shadow: none;
    border-radius: 4px;
    &:focus {
      border-color: $primary-color;
      box-shadow: none;
    }
  }
}
